import React from 'react';
import styled from 'styled-components';
import { IPublication } from '../types/IPublication';

const PublicationLineCardStyles = styled.article`
  /* box-shadow:  2px 2px 4px rgba(rgb(0, 0, 0), 0.8), -1px -1px 8px rgba(rgb(0, 0, 0), 0.8); */
  box-shadow: 0 0px 2px rgba(${({ theme }) => theme.color.border}, 0.7), 0 3px 10px rgba(${({ theme }) => theme.color.border}, 0.2);
  border-radius: ${({ theme }) => theme.radius.default};
  padding: ${({ theme }) => theme.spacing['inner-card']};
  margin-bottom: 2.5rem;

  .publication-card__header {
    color: ${({ theme }) => theme.color.primary};

    .publication-card__title {
      margin-top: 0;
      font-weight: ${({ theme }) => theme.font.weight.normal};
      margin-bottom: 1rem;
    }
  }

  .publication-card__description {
    color: ${({ theme }) => theme.color.primary};
    margin-bottom: 1.25rem;
  }

  .publication-card__footer {
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.color.primary};
    flex-wrap: wrap;
    gap: 0.5rem;

    .published-at {
      font-weight: ${({ theme }) => theme.font.weight.light};
    }
  }
`;

export default function PublicationLineCard({ publication }: { publication: IPublication }) {
  return (
    <PublicationLineCardStyles>
      <header className="publication-card__header">
        <h1 className="publication-card__title">{publication.title}</h1>
      </header>
      <p className="publication-card__description">{publication.description}</p>
      <footer className="publication-card__footer">
        <div>
          <a className="link" href={`${publication.file.asset.url}?dl=${publication.file.asset.originalFilename}`}>
            Télécharger le document
          </a>
        </div>
        <div className="published-at">Publié le: {publication.publishedAt} </div>
      </footer>
    </PublicationLineCardStyles>
  );
}
