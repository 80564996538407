import { graphql, PageProps } from 'gatsby';
import React from 'react';
import PublicationLineCard from '../../components/PublicationLineCard';
import SEO from '../../components/SEO';
import { INode } from '../../types/INode';
import { IPublication } from '../../types/IPublication';
import { ITheme } from '../../types/ITheme';

import '../../assets/style/utilities/wrapper.css';
import '../../assets/style/font.css';

type SanityDataProps = {
  allSanityPublication: {
    edges: INode<IPublication>[];
  };
  sanityTheme: Partial<ITheme>;
};

export default function PublicationsPage(props: PageProps<SanityDataProps>) {
  const { edges: publications } = props.data.allSanityPublication;

  return (
    <>
      <SEO title={`${props.data.sanityTheme.title}`} />
      <h1 className="page-title">{props.data.sanityTheme.title} </h1>
      {publications.length ? (
        <div>
          {publications.map(({ node: publication }) => {
            return <PublicationLineCard publication={publication} key={publication.id} />;
          })}
        </div>
      ) : (
        <div className="no-content">Il n'y pas de publication pour l'instant</div>
      )}
    </>
  );
}

export const query = graphql`
  # The $id comes automatically from Gatsby
  query MyQuery($id: String) {
    allSanityPublication(filter: { theme: { id: { eq: $id } } }, sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          description
          slug {
            current
          }
          publishedAt(formatString: "DD.MM.YYYY")
          file {
            asset {
              url
              originalFilename
            }
          }
        }
      }
    }

    sanityTheme(id: { eq: $id }) {
      title
    }
  }
`;
